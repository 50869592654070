import React, { useState, useEffect, useContext, Fragment } from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { jsx } from "@emotion/core";
import Header from "../components/Header";
import { rhythm, scale } from "../utils/typography";
import { LocaleContext } from "../context/locale-context";
import useWindowSize from "../components/hooks/useWindowSize";
import { mq } from "../utils/helper";

function HeaderSection() {
  return (
    <div
      css={{
        backgroundColor: "black"
      }}
    >
      <Header />
    </div>
  );
}

function Text({ data, isMobile }) {
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  return (
    <div
      css={{
        maxWidth: rhythm(45),
        margin: "0 auto",
        marginTop: rhythm(4),
        direction: isRTL ? "rtl" : "ltr",
        fontSize: "1.4rem",
        [mq[0]]: {
          maxWidth: "90%",
          fontSize: "1.1rem"
        }
      }}
    >
      <MDXRenderer>{data.body}</MDXRenderer>
    </div>
  );
}

function KeyFinding({ data }) {
  const [windowWidthSize, windowHeightSize] = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  const partials = data.allMdx.edges.map(p => p.node);
  const [text] = partials;
  useEffect(() => {
    windowWidthSize < 992 ? setIsMobile(true) : setIsMobile(false);
  }, [windowWidthSize]);
  return (
    <div>
      <HeaderSection />
      <Text data={text} isMobile={isMobile} />
    </div>
  );
}

export default KeyFinding;

export const pageQuery = graphql`
  query PageTemplate($title: String!, $locale: String!) {
    allMdx(
      filter: { frontmatter: { title: { eq: $title }, lang: { eq: $locale } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            lang
            name
          }
          body
        }
      }
    }
  }
`;
